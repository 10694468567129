'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { Input } from '@noirproperties/ui/components/ui/input';
import { Button } from '@noirproperties/ui/components/ui/button';
import { ArrowUp } from 'lucide-react';
import { cn } from '@/lib/utils';

export function SearchInput({
  placeholder,
  className,
}: {
  placeholder?: string;
  className?: string;
}) {
  const [query, setQuery] = useState('');
  const router = useRouter();

  const destination = `/properties/search?query=${encodeURIComponent(query)}`;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      router.push(destination);
    }
  };

  const handleClick = () => {
    router.push(destination);
  };

  return (
    <div className="flex items-center shadow-sm">
      <Input
        autoComplete="off"
        className={cn('flex-grow rounded-none', className)} // 'flex-grow' allows the input to take up remaining space
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        spellCheck="false"
        value={query}
      />
      <Button
        className="h-20 min-w-20 flex-shrink-0 rounded-none shadow-sm"
        onClick={handleClick}
        size="icon"
      >
        <ArrowUp />
      </Button>
    </div>
  );
}
